import React from 'react'
import { useDispatch } from 'react-redux';
import { setTransactionId } from '../../store/userSlice';
import { useNavigate } from 'react-router-dom';
import { rupiahConvert } from '../../helper/rupiahConvert';

export const CardHistory = (props: any) => {
    const { id, tanggal, code, taken, status, total } = props;
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleClick = () => {
        dispatch(setTransactionId(id))
        navigate('/invoice');
    }

    return (
        <div className="card w-96 bg-base-100 shadow-xl my-3" onClick={handleClick}>
            <div className="card-body">
                <div className="card-title">{tanggal}</div>
                <div className="divider"></div>
                <div>Kode Transaksi : {code}</div>
                <div>Status : {taken === true ?
                    'Sudah di ambil'
                    :
                    status === 'paid' ?
                        'Sudah di bayar'
                        :
                        'Belum di bayar'
                }</div>
                <div>Total : {rupiahConvert(total)}</div>
            </div>
        </div>
    )
}
