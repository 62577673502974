import { faTimesCircle, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { GoogleLogout } from "react-google-login";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DataProfile } from "../../interfaces";
import { RootState } from "../../store/store";
import {
  logout,
  setLocation,
  setUserShop,
  storeToken,
} from "../../store/userSlice";

export const ChoseLocation = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<DataProfile>();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [shop, setShop] = useState([]);
  const globalStateUser = useSelector((state: RootState) => {
    return state.userData;
  });
  const userShop = globalStateUser?.userShop;
  const token = globalStateUser?.token;

  useEffect(() => {
    if (userShop !== null) {
      navigate("/unauthorized");
    }
    const loadShop = async () => {
      const response = await axios.get(
        process.env.REACT_APP_SHOP_AUTH as string,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setShop(response.data.data);
    };
    loadShop();
    if (!token) {
      navigate("/");
    }
  }, []);

  // const onChangeHandler = (text: any) => {
  //     setText(text)
  //     }

  const handleNext = handleSubmit((data) => {
    const dataForm = {
      name: data.name,
      branch: data.location,
    };
    dispatch(setLocation(data?.location));
    axios
      .post(process.env.REACT_APP_SHOP_AUTH as string, dataForm, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        dispatch(storeToken(response.data?.data?.token));
        dispatch(setUserShop(response.data?.data?.name));
        navigate("/unauthorized");
      })
      .catch((error) => {
        console.log(error);
      });
  });

  const handleLogout = () => {
    navigate("/");
    dispatch(logout());
  };

  // const inStyle = { background: "none" };

  return (
    <>
      {/* <div className="bg-base-200">
                <GoogleLogout
                    clientId={(process.env.REACT_APP_GOOGLE_OAUTH_CLIENTID as string)}
                    render={renderProps => (
                        <button onClick={renderProps.onClick} style={inStyle}><FontAwesomeIcon icon={faSignOutAlt} className='fa-rotate-180' size='2x' /></button>
                    )}
                    buttonText="Logout"
                    onLogoutSuccess={handleLogout}
                >
                </GoogleLogout>
            </div> */}
      <div className="hero min-h-screen bg-base-200">
        <div className="flex-col justify-center hero-content lg:flex-row">
          {/* <div className="text-center lg:text-left">
                        <h1 className="mb-5 text-5xl font-bold">
                            Pilih lokasi Pickup Point
                        </h1>
                    </div> */}
          <div className="card flex-shrink-0 w-full max-w-sm shadow-2xl bg-base-100">
            <div className="card-body">
              <form onSubmit={handleNext}>
                <div className="form-control">
                  <label className="label">
                    <span className="label-text">
                      Masukkan Nama Toko Anda :
                    </span>
                  </label>
                  <input
                    type="text"
                    placeholder="Nama Toko"
                    className={`${
                      !errors.name?.type
                        ? "input input-bordered"
                        : "input border-2 border-error"
                    }`}
                    {...register("name", { required: true })}
                  />
                  <div className="label justify-start">
                    {errors.name ? (
                      <FontAwesomeIcon
                        icon={faTimesCircle}
                        className="text-error mr-2"
                      />
                    ) : (
                      ""
                    )}
                    <span className="text-error text-sm font-bold">
                      {errors.name?.type === "required" && "Nama Toko required"}
                    </span>
                  </div>
                </div>
                <div className="form-control">
                  <label className="label">
                    <span className="label-text">
                      Masukkan Lokasi Toko Anda :
                    </span>
                  </label>
                  <select
                    defaultValue=""
                    className={`${
                      !errors.location?.type
                        ? "select select-bordered"
                        : "select select-bordered select-error"
                    }`}
                    {...register(`location`, { required: true })}
                  >
                    <option value="" disabled>
                      Lokasi Toko
                    </option>
                    <option value="JTP1">Jatim Park 1</option>
                    <option value="JTP2">Jatim Park 2</option>
                    <option value="JTP3">Jatim Park 3</option>
                    <option value="ECO">Eco Green Park</option>
                    <option value="SENYUM">Senyum World Hotel</option>
                    <option value="MGG">Milenial Glow Garden</option>
                    <option value="PFP">Predator Fun Park</option>
                    <option value="POHONINN">Pohon Inn Hotel</option>
                    <option value="MA">Museum Angkut</option>
                    <option value="BALOGA">Batu Love Garden</option>
                  </select>
                  <div className="label justify-start">
                    {errors.location ? (
                      <FontAwesomeIcon
                        icon={faTimesCircle}
                        className="text-error mr-2"
                      />
                    ) : (
                      ""
                    )}
                    <span className="text-error text-sm font-bold">
                      {errors.location?.type === "required" &&
                        "Location required"}
                    </span>
                  </div>
                </div>
                <div className="form-control mt-6">
                  <button className="btn btn-primary" type="submit">
                    Lanjut
                  </button>
                  <br />
                  <GoogleLogout
                    clientId={
                      process.env.REACT_APP_GOOGLE_OAUTH_CLIENTID as string
                    }
                    render={(renderProps) => (
                      <button
                        onClick={renderProps.onClick}
                        // style={inStyle}
                        className="btn btn-error"
                      >
                        Logout
                      </button>
                    )}
                    buttonText="Logout"
                    onLogoutSuccess={handleLogout}
                  ></GoogleLogout>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
