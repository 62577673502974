import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setInvoiceBank, setInvoiceJumlah, setInvoiceNorek, setInvoiceRekName, setTransactionId, setUnavailableItems } from '../../store/userSlice';
// import { useCart } from 'react-use-cart';
import { DataTotal } from '../../interfaces';
import Swal from 'sweetalert2';
import { rupiahConvert } from '../../helper/rupiahConvert';
import { locationText } from '../../helper/locationText';

export const Invoice = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    // const { emptyCart, items, cartTotal } = useCart();
    const globalStateUser = useSelector((state: RootState) => {
        return state.userData
    });
    const token = globalStateUser?.token;
    const transactionId = globalStateUser?.transactionId;
    const locationPicked = globalStateUser?.locationPicked;
    const [totalAmount, setTotalAmount] = useState<DataTotal>({
        status: '',
        code: '',
        codePaid: '',
        orderDate: '',
        toko: '',
        expiration: '',
        discount: 0,
        discount_amount: 0,
        gross_amount: 0,
        totalAmounts: 0,
        tax_base_amount: 0,
        tax_amount: 0,
        dateTaken: '',
        platform_charge: 0,
        final_pay_amount: 0,
        no_va: 0,
        rek_an: 0,
        token: ''
    });
    const [itemsOut, setItemsOut] = useState([]);
    const dataCheckout = {
        transaction_id: transactionId
    }
    // const [dataInvoice, setDataInvoice] = useState({
    //     bank: null,
    //     jumlah: 0,
    //     norek: null,
    //     rekname: null
    // })

    useEffect(() => {
        if (!transactionId) {
            navigate('/foods');
        }
        // axios.post(
        //     (process.env.REACT_APP_CHECKOUT as string),
        //     dataCheckout,
        //     {
        //         headers: {
        //             "Authorization": `Bearer ${token}`
        //         }
        //     }
        // )
        //     .then(response => {
        //         if (response.data.message === 'proceed invoice') {
        //             emptyCart();
        //             dispatch(setTransactionId(null))
        //             dispatch(setUnavailableItems(null))
        //             setDataInvoice({
        //                 ...dataInvoice,
        //                 bank: response.data.data.bank,
        //                 jumlah: response.data.data.final_pay_amount,
        //                 norek: response.data.data.account_number,
        //                 rekname: response.data.data.account_name
        //             })
        //         } else if (response.data.message === 'your transaction has been checked out') {
        //             setDataInvoice({
        //                 ...dataInvoice,
        //                 bank: response.data.data.bank,
        //                 jumlah: response.data.data.final_pay_amount,
        //                 norek: response.data.data.account_number,
        //                 rekname: response.data.data.account_name
        //             })
        //         } else if (response.data.message === 'please change your order qty') {
        //             dispatch(setUnavailableItems(response.data.data))
        //             navigate('/checkout')
        //         }
        //     })
        //     .catch(error => {
        //         console.log(error)
        //     })
    }, [])

    useEffect(() => {
        axios.get(
            (process.env.REACT_APP_HISTORY_BY_ID as string) + transactionId,
            {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            }
        )
            .then(response => {
                setItemsOut(response.data.data.items)
                if (response.data.data.transaction_status === 'taken') {
                    setTotalAmount({
                        ...totalAmount,
                        status: response.data.data.transaction_status,
                        code: response.data.data.code,
                        orderDate: response.data.data.logs[0].date,
                        toko: response.data.data.shop.name,
                        expiration: response.data.data.expired_date,
                        discount: response.data.data.discount,
                        discount_amount: response.data.data.discount_amount,
                        gross_amount: response.data.data.gross_amount,
                        totalAmounts: response.data.data.pay_amount,
                        tax_base_amount: response.data.data.tax_base_amount,
                        tax_amount: response.data.data.tax_amount,
                        dateTaken: response.data.data.logs[3].date,
                        codePaid: response.data.data.logs[2].code,
                        platform_charge: response.data.data.platform_charge,
                        final_pay_amount: response.data.data.final_pay_amount,
                        no_va: response.data.data.bank.account_number,
                        rek_an: response.data.data.bank.account_name
                    })
                } else if (response.data.data.transaction_status === 'paid') {
                    setTotalAmount({
                        ...totalAmount,
                        status: response.data.data.transaction_status,
                        code: response.data.data.code,
                        orderDate: response.data.data.logs[0].date,
                        toko: response.data.data.shop.name,
                        expiration: response.data.data.expired_date,
                        discount: response.data.data.discount,
                        discount_amount: response.data.data.discount_amount,
                        gross_amount: response.data.data.gross_amount,
                        totalAmounts: response.data.data.pay_amount,
                        tax_base_amount: response.data.data.tax_base_amount,
                        tax_amount: response.data.data.tax_amount,
                        codePaid: response.data.data.logs[2].code,
                        platform_charge: response.data.data.platform_charge,
                        final_pay_amount: response.data.data.final_pay_amount,
                        no_va: response.data.data.bank.account_number,
                        rek_an: response.data.data.bank.account_name,
                        token: response.data.data.token.code
                    })
                } else {
                    setTotalAmount({
                        ...totalAmount,
                        status: response.data.data.transaction_status,
                        code: response.data.data.code,
                        orderDate: response.data.data.logs[0].date,
                        toko: response.data.data.shop.name,
                        expiration: response.data.data.expired_date,
                        discount: response.data.data.discount,
                        discount_amount: response.data.data.discount_amount,
                        gross_amount: response.data.data.gross_amount,
                        totalAmounts: response.data.data.pay_amount,
                        tax_base_amount: response.data.data.tax_base_amount,
                        tax_amount: response.data.data.tax_amount,
                        platform_charge: response.data.data.platform_charge,
                        final_pay_amount: response.data.data.final_pay_amount,
                        no_va: response.data.data.bank.account_number,
                        rek_an: response.data.data.bank.account_name
                    })
                }
            })
            .catch(error => {
                console.log(error)
            })
    }, []);

    useEffect(() => {
        return () => {
            dispatch(setTransactionId(null))
            dispatch(setInvoiceBank(null))
            dispatch(setInvoiceJumlah(null))
            dispatch(setInvoiceNorek(null))
            dispatch(setInvoiceRekName(null))
        }
    }, [])

    const handleTaken = () => {
        // Swal.fire({
        //     title: 'Yakin mengambil barang?',
        //     text: "Pastikan anda menekan tombol 'Ambil Barang' di depan petugas gudang!",
        //     icon: 'warning',
        //     showCancelButton: true,
        //     confirmButtonColor: '#3085d6',
        //     cancelButtonColor: '#d33',
        //     confirmButtonText: 'Proses!'
        // }).then((result) => {
        //     if (result.isConfirmed) {
        //     }
        // })
        axios.post(
            (process.env.REACT_APP_HISTORY_TAKEN as string),
            dataCheckout,
            {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            }
        )
            .then(response => {
                // Swal.fire(
                //     'Taken!',
                //     'Barang sudah diambil.',
                //     'success'
                // )
                navigate('/foods');
            })
            .catch(error => {
                console.log(error);
            })
    }

    // const date = new Date(totalAmount.updatedAt)
    // date.setDate(date.getDate() + 1)

    return (
        <>
            {
                totalAmount.status === "paid" || totalAmount.status === "taken" ?
                    null
                    :
                    <>
                        <div className='card shadow-2xl card-bordered m-3 p-3'>
                            <div className="text-lg">Batas Akhir Pembayaran</div>
                            <div className="text-base italic">{totalAmount.expiration}</div>
                            {/* {console.log('date', totalAmount.expiration)} */}
                            {/* <Countdown date={Date.now() + 86400000}>
                                <p>Expired!</p>
                            </Countdown> */}
                        </div>

                        <div className='card shadow-2xl card-bordered m-3 p-3'>
                            <div className="text-lg">Transfer ke BCA Virtual Account</div>
                            {/* <div className="text-2xl">{dataInvoice.bank}</div> */}
                            <div className="divider" />
                            {/* <div className="text-lg">Nomor Rekening BCA Virtual Account</div> */}
                            <div className="text-lg">No Virtual Account <br /> {totalAmount.no_va}</div>
                            <div className="text-lg">Rek a/n : {totalAmount.rek_an}</div>
                            <div className="divider" />
                            <div className="text-xl">Jumlah : Rp. {rupiahConvert(totalAmount.final_pay_amount)}</div>
                        </div>
                    </>
            }

            {/* <div className='card shadow-2xl card-bordered m-3 p-3'>
                <div className="text-xl">Lokasi Pengambilan : {locationText(locationPicked)}</div>
            </div> */}

            <div className='card shadow-2xl card-bordered m-3 p-3'>
                {/* <div className="text-xl">Setelah transfer harap kirimkan bukti transfer ke <br /> <a href='https://api.whatsapp.com/send?phone=+6281931033344' target="_blank" className='bg-green-600 text-white'>Whatsapp Pak Chrisye</a></div> */}
                <div className="text-xl">Setelah transfer harap cek status Invoice pada menu history, dan bisa melakukan pengambilan barang di gudang</div>
            </div>
            <div className='card shadow-2xl card-bordered m-3 p-3'>
                <div className="text-lg">Status : {totalAmount.status === 'paid' ?
                    'Sudah di bayar'
                    :
                    totalAmount.status === 'taken' ?
                        'Sudah di ambil'
                        :
                        'Belum di bayar'
                }</div>
                {
                    totalAmount.status === 'taken' ?
                        <div className="text-lg">Diambil Tanggal : {totalAmount.dateTaken}</div>
                        :
                        null
                }
            </div>

            <div className='card shadow-2xl card-bordered m-3 p-3'>
                <h1 className='text-xl text-center'>Invoice</h1>
                <div className="divider" />
                <div className="text-lg">Lokasi Pengambilan : {locationText(locationPicked)}</div>
                <div className="text-lg">Trx : {totalAmount.code}</div>
                <div className="text-lg">{totalAmount.codePaid && `Code Bayar : ${totalAmount.codePaid}`}</div>
                <div className="text-lg">{totalAmount.codePaid && `Code Pengambilan : ${totalAmount.token}`}</div>
                <div className="divider" />
                <div className="text-lg">Toko : <br /><span className='italic'>{totalAmount.toko}</span></div>
                <div className="text-lg">Tanggal Order : <br /><span className='italic'>{totalAmount.orderDate}</span></div>
                <div className="divider" />
                {
                    itemsOut.map((data: any) => {
                        return (
                            <div key={data.id} className="flex justify-between flex-cols">
                                <div>{data.qty} x&nbsp;</div>
                                <div className='grow'>&nbsp;&nbsp;{data.name}</div>
                                <div className='grow text-right'>{rupiahConvert(data.total_price)}</div>
                            </div>
                        )
                    })
                }
                <div className="divider"></div>
                <div className="flex justify-between flex-cols">
                    <div className='grow'>Subtotal</div>
                    <div className='grow text-right'>Rp. {rupiahConvert(totalAmount.gross_amount)}</div>
                </div>
                {
                    totalAmount.discount === 0 ?
                        null
                        :
                        <>
                            <div className="flex justify-between flex-cols">
                                <div className='grow italic'>Discount {totalAmount.discount}%</div>
                                <div className='grow text-right'><span className='italic'>Rp. {rupiahConvert(totalAmount.discount_amount)}</span></div>
                            </div>
                        </>
                }
                {
                    totalAmount.discount === 0 ?
                        null
                        :
                        <div className="flex justify-between flex-cols">
                            <div className='grow italic'>Total Setelah Discount</div>
                            <div className='grow text-right'><span>Rp. {rupiahConvert(totalAmount.totalAmounts)}</span></div>
                        </div>
                }
                <div className="flex justify-between flex-cols">
                    <div className='grow'>Dasar Pengenaan Pajak</div>
                    <div className='grow text-right'><span>Rp. {rupiahConvert(totalAmount.tax_base_amount)}</span></div>
                </div>
                <div className="flex justify-between flex-cols">
                    <div className='grow'>PPN</div>
                    <div className='grow text-right'><span>Rp. {rupiahConvert(totalAmount.tax_amount)}</span></div>
                </div>
                <div className="divider"></div>
                <div className="flex justify-between flex-cols">
                    <div className='grow italic'>Biaya Layanan</div>
                    <div className='grow text-right'><span className='italic'>Rp. {rupiahConvert(totalAmount.platform_charge)}</span></div>
                </div>
                <div className="divider"></div>
                <div className="flex justify-between flex-cols">
                    <div className='grow'>Grand Total</div>
                    <div className='grow text-right'>Rp. {rupiahConvert(totalAmount.final_pay_amount)}</div>
                </div>
            </div>
            {
                totalAmount.status === "paid" ?
                    <button className='btn btn-primary w-full my-3' onClick={handleTaken}>Ambil Barang</button>
                    :
                    null
            }
        </>
    );
};
