import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { CardHistory } from '../../components/CardHistory/CardHistory';
import { RootState } from '../../store/store';

export const History = () => {
    const globalStateUser = useSelector((state: RootState) => {
        return state.userData
    });
    const token = globalStateUser?.token;
    const [historyTransaksi, setHistoryTransaksi] = useState([]);

    useEffect(() => {
        axios.get(
            (process.env.REACT_APP_HISTORY as string)
            , {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            }
        )
            .then(response => {
                setHistoryTransaksi(response.data.data)
            })
            .catch(error => {
                console.log(error)
            })
    }, []);

    return (
        <>
            {
                historyTransaksi.map((data: any, idx) => {
                    return (
                        <CardHistory key={idx} id={data.id} tanggal={data.order_date} code={data.code} taken={data.taken} status={data.transaction_status} total={data.pay_amount === 0 ? data.pay_amount : data.final_pay_amount} />
                    )
                })
            }
        </>
    );
};
