import React from 'react';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

export const CardLoading = () => {
    return (
        <>
            <div className="card text-center item shadow-2xl mx-2 my-2 max-w-xs">
                <div className='lg:visible md:hidden sm:hidden'>
                    <figure className="px-10 pt-10">
                        <Skeleton
                            height={180}
                            width={240}
                            containerClassName="avatar-skeleton"
                        />
                    </figure>
                </div>
                <div className="card-body">
                    <Skeleton width={240} />
                    <Skeleton width={240} />
                    <br />
                    <Skeleton width={240} height={50} />
                    <div className="btn-group flex items-center">
                    </div>
                </div>
            </div>
        </>
    );
};
