import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
    name: 'userData',
    initialState: {
        user: null,
        userShop: null,
        userStatus: null,
        locationPicked: null,
        token: null,
        transactionId: null,
        unavailableItems: [],
        invoiceBank: null,
        invoiceJumlah: null,
        invoiceNorek: null,
        invoiceRekName: null
    },
    reducers: {
        login: (state, action) => {
            state.user = action.payload
        },
        logout: (state) => {
            state.user = null
            state.locationPicked = null
            state.token = null
            state.userShop = null
            state.userStatus = null
            state.transactionId = null
            state.unavailableItems = []
        },
        setLocation: (state, action) => {
            state.locationPicked = action.payload
        },
        setUserShop: (state, action) => {
            state.userShop = action.payload
        },
        setUserStatus: (state, action) => {
            state.userStatus = action.payload
        },
        storeToken: (state, action) => {
            state.token = action.payload
        },
        setTransactionId: (state, action) => {
            state.transactionId = action.payload
        },
        setUnavailableItems: (state, action) => {
            state.unavailableItems = action.payload
        },
        setInvoiceBank: (state, action) => {
            state.invoiceBank = action.payload
        },
        setInvoiceJumlah: (state, action) => {
            state.invoiceJumlah = action.payload
        },
        setInvoiceNorek: (state, action) => {
            state.invoiceNorek = action.payload
        },
        setInvoiceRekName: (state, action) => {
            state.invoiceRekName = action.payload
        }
    }
});

export const userReducer = userSlice.reducer;

export const { login, logout, setLocation, storeToken, setUserShop, setUserStatus, setTransactionId, setUnavailableItems, setInvoiceBank, setInvoiceJumlah, setInvoiceNorek, setInvoiceRekName } = userSlice.actions;
