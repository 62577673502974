import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';
import { useCart } from 'react-use-cart';
import { CardCheckout } from '../../components/CardCheckout/CardCheckout';
import { RootState } from '../../store/store';
import { useDispatch } from 'react-redux';
import { setInvoiceBank, setInvoiceJumlah, setInvoiceNorek, setInvoiceRekName, setTransactionId, setUnavailableItems } from '../../store/userSlice';
import Swal from 'sweetalert2';
import { rupiahConvert } from '../../helper/rupiahConvert';

export const Checkout = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {
        emptyCart,
        isEmpty,
        items,
        cartTotal,
        totalItems
    } = useCart();
    const globalStateUser = useSelector((state: RootState) => {
        return state.userData
    });
    const token = globalStateUser?.token;
    const unavailableItem = globalStateUser?.unavailableItems;

    useEffect(() => {
        return () => {
            dispatch(setUnavailableItems([]))
        }
    }, [])

    const handleNext = () => {
        const reqDatas = items.map((data: any) => {
            return {
                product_id: data.product.id,
                qty: data.quantity,
                price: data.price,
                total_price: data.itemTotal
            }
        })
        const reqData = {
            items: reqDatas
        }
        axios.post(
            (process.env.REACT_APP_CART as string),
            reqData,
            {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            }
        )
            .then(response => {
                if (response.data.message === 'please change your order qty') {
                    dispatch(setUnavailableItems(response.data.data))
                    navigate('/checkout')
                } else {
                    dispatch(setTransactionId(response.data.data.transaction.id))
                    emptyCart();
                    dispatch(setUnavailableItems(null))
                    dispatch(setInvoiceBank(response.data.data.bank.bank))
                    dispatch(setInvoiceJumlah(response.data.data.bank.final_pay_amount))
                    dispatch(setInvoiceNorek(response.data.data.bank.account_number))
                    dispatch(setInvoiceRekName(response.data.data.bank.account_name))
                    navigate('/invoice');
                }
            })
            .catch(error => {
                console.log(error);
            })
        // Swal.fire({
        //     title: 'Yakin melakukan transaksi ?',
        //     text: "Periksa kembali pesanan anda!",
        //     icon: 'warning',
        //     showCancelButton: true,
        //     confirmButtonColor: '#3085d6',
        //     cancelButtonColor: '#d33',
        //     confirmButtonText: 'Proses!'
        // }).then((result) => {
        //     if (result.isConfirmed) {


        //     }
        // })
    }

    if (isEmpty) return <Navigate to='/foods' />

    return (
        <>
            <div className="flex flex-col justify-center lg:flex-row">
                {unavailableItem && unavailableItem.map((data: any, idx) => {
                    return (
                        <p key={idx} className='text-red-600'>{data.name} stock yg tersedia {data.current_stock}</p>
                    )
                })}
                <div className='basis-1/2'>
                    {items.map((data: any) => {
                        return <CardCheckout key={data.id} item={data} thumbnail={data.product.photo} name={data.product.name} quantity={data.quantity} price={data.price} total={data.itemTotal} />
                    })}

                </div>
                {/* <div className='basis-1/4'>
                    <div className='card shadow-2xl card-bordered m-3 p-3'>
                        <h1 className='text-2xl text-center'>Invoice</h1>
                        <div className="divider"></div>
                        {
                            items.map((data: any) => {
                                return (
                                    <div key={data.id} className="flex justify-between flex-cols">
                                        <div className='grow'>{data.product.name}</div>
                                        <div className='grow text-right'>{data.itemTotal}</div>
                                    </div>
                                )
                            })
                        }
                        <div className="divider"></div>
                        <div className="flex justify-between flex-cols">
                            <div className='grow'>Total</div>
                            <div className='grow text-right'>Rp. {cartTotal}</div>
                        </div>
                    </div>
                </div> */}
                <div className='flex flex-row justify-between bg-slate-300 p-4 fixed bottom-0 left-0 right-0 z-50 lg:invisible'>
                    <p className='text-stone-600'>Total Harga <br /> <span className='font-bold'>Rp. {rupiahConvert(cartTotal)}</span></p>
                    <p className='text-stone-600'>
                        <button type="submit" onClick={handleNext} className='btn btn-primary'>
                            Beli ({totalItems})
                        </button>
                    </p>
                </div>
            </div>
        </>
    );
};
