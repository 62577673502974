import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUtensils, faWineGlass, faCircle } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

export const Carousel = () => {
    return (
        <>
            <div className="mx-3 carousel lg:hidden">
                <div className="carousel-item mx-3 flex flex-col">
                    <div>
                        <Link to='/foods'>
                            <FontAwesomeIcon icon={faUtensils} size='4x' mask={faCircle} transform="shrink-3.5" />
                        </Link>
                    </div>
                    <div className='text-center font-light text-sm'>
                        <p>Foods</p>
                    </div>
                </div>
                <div className="carousel-item mx-3 flex flex-col">
                    <div>
                        <Link to='/drinks'>
                            <FontAwesomeIcon icon={faWineGlass} size='4x' mask={faCircle} transform="shrink-3.5" />
                        </Link>
                    </div>
                    <div className='text-center font-light text-sm'>
                        <p>Drinks</p>
                    </div>
                </div>
            </div>
        </>
    );
};
