import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useCart } from 'react-use-cart';
import { rupiahConvert } from '../../helper/rupiahConvert';

export const Basket = () => {
    const navigate = useNavigate();
    const {
        totalItems,
        cartTotal,
    } = useCart();

    return (
        <>
            <button onClick={() => navigate('/checkout')}>
                <div className='flex flex-row justify-evenly bg-green-500 p-3 m-5 rounded-2xl fixed bottom-0 left-0 right-0 z-50 lg:invisible'>
                    <p className='text-white'>Keranjang | {totalItems} item</p>
                    <p className='text-white'>Rp. {rupiahConvert(cartTotal)}</p>
                </div>
            </button>
        </>
    );
};
