import { FoodData } from '../../interfaces';
import { useCart } from 'react-use-cart';
import { rupiahConvert } from '../../helper/rupiahConvert';

export const Card = (props: any) => {
    const { total, stock_unit, product }: FoodData = props;
    const { addItem } = useCart();

    return (
        <>
            <div className="card flex flex-row justify-items-start items-center shadow-xl w-full my-2 lg:mx-2 lg:flex-col lg:max-w-xs lg:text-center">
                <div className="basis-1/3">
                    <figure className="lg:px-10 lg:pt-10">
                        <img src={product.photo} className="h-32 object-cover lg:rounded-xl" alt='' />
                    </figure>
                </div>

                <div className="mx-3 my-3">
                    <div className="lg:card-body">
                        <p className='text-lg font-extrabold'>{product.name}</p>
                        <p className='text-base'>{stock_unit}</p>
                        <p className='text-base'>{`Rp. ${rupiahConvert(product.price)}`}</p>
                        <p className='text-base italic'>Stock {total}</p>
                        <div className="btn-group inline-flex w-full">
                            <button className="btn" onClick={() => addItem(props.item)}> + Keranjang </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
