import React from 'react';
import { Drawer } from './components/Drawer/Drawer';
import { Routes, Route } from 'react-router-dom';
import { Foods } from './pages/Foods/Foods';
import { Drinks } from './pages/Drinks/Drinks';
import { Login } from './pages/Login/Login';
import { Checkout } from './pages/Checkout/Checkout';
import { ChoseLocation } from './pages/ChooseLocation/ChooseLocation';
import ScrollToTop from './ScrollToTop';
import { Profile } from './pages/Profile/Profile';
import { PrivateRoute } from './components/PrivateRoute/PrivateRoute';
import { History } from './pages/History/History';
import { Invoice } from './pages/Invoice/Invoice';
import { Unauthorized } from './pages/Unauthorized/Unauthorized';
import { NoShop } from './components/NoShop/NoShop';

function App() {
  return (
    <>
      <ScrollToTop>
        <Routes>
          <Route path='/' element={<Login />} />
          <Route element={<NoShop />}>
            <Route path='/locationpick' element={<ChoseLocation />} />
            <Route path='/unauthorized' element={<Unauthorized />} />
            <Route element={<PrivateRoute />}>
              <Route element={<Drawer />}>
                <Route path='/foods' element={<Foods />} />
                <Route path='/drinks' element={<Drinks />} />
                <Route path='/checkout' element={<Checkout />} />
                <Route path='/profile' element={<Profile />} />
                <Route path='/history' element={<History />} />
                <Route path='/invoice' element={<Invoice />} />
              </Route>
            </Route>
          </Route>
        </Routes>
      </ScrollToTop>
    </>
  );
}

export default App;
