import { useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { LoginData } from '../../interfaces';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { GoogleLogin } from 'react-google-login';
import { useDispatch, useSelector } from 'react-redux';
import { login, setLocation, setUserShop, setUserStatus, storeToken } from '../../store/userSlice';
import axios from 'axios';
import { RootState } from '../../store/store';
import { useEffect, useState } from 'react';

export const Login = () => {
    const { register, handleSubmit, formState: { errors } } = useForm<LoginData>();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const onLoginSuccess = (data: any) => {
        dispatch(login(data.profileObj))
        const dataGetTokenFromApi = {
            name: data.profileObj.name,
            role: "tenant",
            email: data.profileObj.email,
            google_id: data.profileObj.googleId
        }
        axios.post(
            (process.env.REACT_APP_GET_TOKEN as string),
            dataGetTokenFromApi
        )
            .then(response => {
                dispatch(storeToken(response.data?.data?.token))
                dispatch(setUserStatus(response.data?.data?.user_status))
                if (response.data?.data.shop) {
                    dispatch(setUserShop(response.data?.data.shop.name))
                    dispatch(setLocation(response.data?.data.shop.branch))
                }
                if (response.data?.data?.shop === null && response.data?.data?.user_status === false) {
                    navigate('/locationpick')
                } else if (response.data?.data?.shop !== null && response.data?.data?.user_status === false) {
                    navigate('/unauthorized')
                } else {
                    navigate('/foods')
                }
            })
            .catch(error => {
                console.log(error);
            })
    }

    const onLoginFailure = (error: any) => {
        console.log(error)
    }

    return (
        <>
            <div className="hero min-h-screen bg-base-200">
                <div className="flex-col justify-center hero-content lg:flex-row">
                    <div className="text-center lg:text-left">
                        <h1 className="mb-5 text-5xl font-bold">
                            Jatim Park Distribution Center
                        </h1>
                    </div>
                    <div className="card flex-shrink-0 w-full max-w-sm shadow-2xl bg-base-100">
                        <div className="card-body">
                            {/* <form onSubmit={handleLogin}>
                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text">Email</span>
                                    </label>
                                    <input type="text" placeholder="email" className={`${!errors.email?.type ? 'input input-bordered' : 'input border-2 border-error'}`} {...register('email', { required: true })} />
                                    <div className="label justify-start">
                                        {errors.email ? <FontAwesomeIcon icon={faTimesCircle} className='text-error mr-2' /> : ""}
                                        <span className='text-error text-sm font-bold'>
                                            {errors.email?.type === "required" && "Email required"}
                                        </span>
                                    </div>
                                </div>
                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text">Password</span>
                                    </label>
                                    <input type="password" placeholder="password" className={`${!errors.password?.type ? 'input input-bordered' : 'input border-2 border-error'}`} {...register('password', { required: true })} />
                                    <div className="label justify-start">
                                        {errors.password ? <FontAwesomeIcon icon={faTimesCircle} className='text-error mr-2' /> : ""}
                                        <span className='text-error text-sm font-bold'>
                                            {errors.password?.type === "required" && "Password required"}
                                        </span>
                                    </div>
                                    <label className="label">
                                    </label>
                                </div>
                                <div className="form-control mt-6">
                                    <button
                                        className='btn btn-primary'
                                        type='submit'>
                                        Login
                                    </button>
                                </div>
                            </form> */}
                            <div className="form-control mt-6">
                                <p className='text-2xl text-center'>Sign in with Google</p>
                                <br />
                                <GoogleLogin
                                    clientId={(process.env.REACT_APP_GOOGLE_OAUTH_CLIENTID as string)}
                                    buttonText="Sign In"
                                    onSuccess={onLoginSuccess}
                                    onFailure={onLoginFailure}
                                    cookiePolicy={'single_host_origin'}
                                    isSignedIn={true}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
