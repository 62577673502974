import React from 'react';
import { useForm } from "react-hook-form";
import { DataProfile } from '../../interfaces';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { locationText } from '../../helper/locationText';

export const Profile = () => {
    const { register, handleSubmit, formState: { errors }, setValue } = useForm<DataProfile>();
    const globalStateUser = useSelector((state: RootState) => {
        return state.userData
    });
    const { name, email }: any = globalStateUser?.user;
    const locationPicked = globalStateUser?.locationPicked;
    const userShop = globalStateUser?.userShop;

    return (
        <>
            <div className='card flex-shrink-0 w-full max-w-sm shadow-2xl bg-base-100'>
                <div className="card-body">
                    <p className='font-bold'>Name : </p>
                    <p className='font-light'>&nbsp;&nbsp;{name}</p>
                    <p className='font-bold'>Email : </p>
                    <p className='font-light'>&nbsp;&nbsp;{email}</p>
                    <p className='font-bold'>Toko : </p>
                    <p className='font-light'>&nbsp;&nbsp;{userShop}</p>
                    <p className='font-bold'>Location : </p>
                    <p className='font-light'>&nbsp;&nbsp;{locationText(locationPicked)}</p>
                    {/* <br /> */}
                    {/* <form onSubmit={handleSend}> */}
                    {/* <div className="form-control">
                            <label className="label">
                                <span className="label-text">Name</span>
                            </label>
                            <input type="text" placeholder="name" disabled className={`${!errors.name?.type ? 'input input-bordered' : 'input border-2 border-error'}`} {...register('name', { required: true })} />
                            <div className="label justify-start">
                                {errors.name ? <FontAwesomeIcon icon={faTimesCircle} className='text-error mr-2' /> : ""}
                                <span className='text-error text-sm font-bold'>
                                    {errors.name?.type === "required" && "Name required"}
                                </span>
                            </div>
                        </div>
                        <div className="form-control">
                            <label className="label">
                                <span className="label-text">Email</span>
                            </label>
                            <input type="text" placeholder="email" disabled className={`${!errors.email?.type ? 'input input-bordered' : 'input border-2 border-error'}`} {...register('email', { required: true })} />
                            <div className="label justify-start">
                                {errors.email ? <FontAwesomeIcon icon={faTimesCircle} className='text-error mr-2' /> : ""}
                                <span className='text-error text-sm font-bold'>
                                    {errors.email?.type === "required" && "Email required"}
                                </span>
                            </div>
                        </div> */}
                    {/* <div className="form-control">
                            <label className="label">
                                <span className="label-text">Location</span>
                            </label>
                            <select defaultValue="" className={`${!errors.location?.type ? 'select select-bordered' : 'select select-bordered select-error'}`} {...register(`location`, { required: true })}>
                                <option value="" disabled>Pickup Point</option>
                                <option value="JTP1">Jatim Park 1</option>
                                <option value="JTP2">Jatim Park 2</option>
                                <option value="JTP3">Jatim Park 3</option>
                            </select>
                            <div className="label justify-start">
                                {errors.location ? <FontAwesomeIcon icon={faTimesCircle} className='text-error mr-2' /> : ""}
                                <span className='text-error text-sm font-bold'>{errors.location?.type === "required" && "Location required"}</span>
                            </div>
                        </div>
                        <div className="form-control mt-6">
                            <button
                                className='btn btn-primary'
                                type='submit'>
                                Save
                            </button>
                        </div>
                    </form> */}
                </div>
            </div>
        </>
    );
};
