import { useState } from 'react';
import { useCart } from 'react-use-cart';
import { CardCheckoutProps } from '../../interfaces';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { rupiahConvert } from '../../helper/rupiahConvert';

export const CardCheckout = ({ item, thumbnail, name, quantity, price, total }: CardCheckoutProps) => {
    const {
        updateItemQuantity,
        removeItem
    } = useCart();

    return (
        <>
            <div className="card flex flex-row justify-items-start items-center shadow-xl w-full my-2 lg:mx-2 lg:flex-col lg:max-w-xs lg:text-center">
                <div className="basis-1/3">
                    <figure className="lg:px-10 lg:pt-10">
                        <img src={thumbnail} className="h-32 object-cover lg:rounded-xl" alt='' />
                    </figure>
                </div>

                <div className="mx-3 my-3">
                    <div className="lg:card-body">
                        <p className='text-lg font-extrabold'>{name}</p>
                        <p className='text-base'>Price : {rupiahConvert(price)}</p>
                        <p className='text-base'>Total : {rupiahConvert(total)}</p>
                        <div className="btn-group inline-flex w-full">
                            <button onClick={() => removeItem(item.id)}><FontAwesomeIcon icon={faTrash} className='mr-7' size='2x' color='gray' /></button>
                            <button className='btn basis-1 text-2xl' onClick={() => updateItemQuantity(item.id, item.quantity - 1)}> - </button>
                            <button className='btn-outline basis-1 mx-8' disabled>{quantity}</button>
                            <button className="btn basis-1 text-2xl" onClick={() => updateItemQuantity(item.id, item.quantity + 1)}> + </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
