import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Card } from '../../components/Card/Card';
import { CardLoading } from '../../components/CardLoading/CardLoading';
import { FoodData } from '../../interfaces';
import { RootState } from '../../store/store';

export const Foods = () => {
    const [foodsData, setFoodsData] = useState<FoodData[]>();
    const [isLoading, setIsLoading] = useState(false);
    const globalStateUser = useSelector((state: RootState) => {
        return state.userData
    });
    const token = globalStateUser?.token;

    useEffect(() => {
        setIsLoading(true);
        const foodsTemp: FoodData[] = [];
        axios.get(
            (process.env.REACT_APP_FOODS as string) + 'makanan'
            , {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            }
        )
            .then(response => {
                if (response.data.data.length !== 0) {
                    response.data?.data?.filter((data: any) => data.total > 0)
                        .map((data: any) => {
                            return {
                                branch: data.branch,
                                id: data.id,
                                price: data.sale_price,
                                product: {
                                    category: data.category,
                                    id: data.id,
                                    name: data.name,
                                    photo: data.photo,
                                    photo_1: data.photo_1,
                                    photo_2: data.photo_2,
                                    photo_3: data.photo_3,
                                    photo_4: data.photo_4,
                                    price: data.sale_price,
                                    stock_unit: data.stock_unit,
                                    supplier_code: data.supplier_code,
                                    supplier_price: data.supplier_price,
                                    type: data.type
                                },
                                product_id: data.product_id,
                                stock_unit: data.stock_unit,
                                total: data.total
                            }
                        })
                        .forEach((data: any) => {
                            return foodsTemp.push(data);
                        });
                    setFoodsData(foodsTemp);
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                    return null
                }
                setIsLoading(false);
            })
            .catch(error => {
                console.log(error);
                setIsLoading(false);
            })
    }, []);

    return (
        <>
            <div className="flex md:flex-col lg:flex-row flex-wrap place-content-center">
                {
                    isLoading ?
                        <>
                            <CardLoading />
                            <CardLoading />
                            <CardLoading />
                            <CardLoading />
                            <CardLoading />
                            <CardLoading />
                        </>
                        :
                        foodsData === undefined ?
                            'No Data'
                            :
                            foodsData?.map((data, idx) => {
                                return <Card key={idx} total={data.total} stock_unit={data.stock_unit} product={data.product} item={data} quantity={data.quantity} />
                            })
                }
            </div>
        </>
    );
};
