import React, { useEffect, useState } from 'react';
import { Link, Outlet } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUtensils, faWineGlass, faShoppingCart, faArrowLeft, faFile, faComment } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useLocation } from 'react-router-dom';
import { Carousel } from '../Carousel/Carousel';
import { Basket } from '../Basket/Basket';
import { GoogleLogout } from 'react-google-login';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../store/userSlice'
import { RootState } from '../../store/store';
import { useCart } from 'react-use-cart';
import { locationText } from '../../helper/locationText';

export const Drawer = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const { emptyCart } = useCart();
    const globalStateUser = useSelector((state: RootState) => {
        return state.userData
    });
    const { imageUrl }: any = globalStateUser?.user;
    const userShop = globalStateUser?.userShop;
    const locationPicked = globalStateUser?.locationPicked;
    const { totalItems } = useCart();

    const handleLogout = () => {
        navigate('/');
        dispatch(logout());
        emptyCart();
    }

    return (
        <>
            <div className="shadow bg-base-200 drawer drawer-mobile ">
                <input id="my-drawer-2" type="checkbox" className="drawer-toggle" />
                {/**
                 * Drawer Content (behind the drawer)
                 */}
                <div className="drawer-content">
                    {/**
                     * NavBar
                     */}
                    <div className="navbar mb-2 shadow-lg bg-neutral text-neutral-content top-0">
                        <div className="flex-none lg:hidden">
                            {
                                location.pathname === `/checkout` || location.pathname === `/profile` || location.pathname === `/history` || location.pathname === `/invoice`
                                    ?
                                    <button className="btn btn-square btn-ghost" onClick={() => navigate('/foods')}>
                                        <FontAwesomeIcon icon={faArrowLeft} size='2x' />
                                    </button>
                                    :
                                    <>
                                        <h1 className='font-extrabold text-xl ml-5'>{
                                            userShop
                                        }<br />{
                                                locationText(locationPicked)
                                            }</h1>
                                    </>
                            }
                        </div>
                        <div className="flex-1 hidden px-2 mx-2 lg:flex">
                            {/* <span className="text-lg font-bold">
                                daisyUI
                            </span> */}
                        </div>
                        <div className="flex-1 lg:flex-none">
                            {/* <div className="form-control">
                                <input type="text" placeholder="Search" className="input input-ghost" />
                            </div> */}
                        </div>
                        <div className="flex-none">
                            {/* <button className="btn btn-square btn-ghost">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="inline-block w-6 h-6 stroke-current">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                                </svg>
                            </button> */}
                        </div>
                        <div className="flex-none lg:visible md:invisible sm:invisible">
                            <button className="my-6 indicator" onClick={() => navigate('/checkout')}>
                                <div className="indicator-item badge bg-current"><span className='text-black'>2</span></div>
                                <FontAwesomeIcon icon={faShoppingCart} size='lg' />
                            </button>
                        </div>
                        <div className="flex-none mr-7">
                            <a href='https://api.whatsapp.com/send?phone=628123305579' target="_blank" className="mb-1 indicator">
                                <FontAwesomeIcon icon={faComment} size='lg' />
                                <div className='indicator-item indicator-bottom indicator-center badge bg-transparent border-opacity-0'><span className='mt-3'>admin</span></div>
                            </a>
                        </div>
                        <div className='flex-none'>
                            <button className="mb-1 indicator" onClick={() => navigate('/history')}>
                                <FontAwesomeIcon icon={faFile} size='lg' color={location.pathname !== `/history` ? '' : `green`} />
                                <div className='indicator-item indicator-bottom indicator-center badge bg-transparent border-opacity-0'><span className={location.pathname !== `/history` ? `mt-3` : `mt-3 text-green-600`}>history</span></div>
                            </button>
                        </div>
                        <div className="flex-none">
                            <div className="dropdown dropdown-end">
                                <div tabIndex={0} className="avatar m-1 btn">
                                    <div className="rounded-full w-10 h-10 m-1">
                                        <img src={imageUrl} alt='' />
                                    </div>
                                </div>
                                <ul tabIndex={0} className="p-2 shadow menu dropdown-content bg-base-100 rounded-box w-52 text-black">
                                    <li>
                                        <Link to='/profile'>Profile</Link>
                                    </li>
                                    {/* <li>
                                        <Link to='/history'>History</Link>
                                    </li> */}
                                    <li>
                                        <GoogleLogout
                                            clientId={(process.env.REACT_APP_GOOGLE_OAUTH_CLIENTID as string)}
                                            render={renderProps => (
                                                <button onClick={renderProps.onClick} disabled={renderProps.disabled}>Logout</button>
                                            )}
                                            buttonText="Logout"
                                            onLogoutSuccess={handleLogout}
                                        >
                                        </GoogleLogout>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/**
                     * End Of NavBar
                     */}
                    {
                        location.pathname === `/checkout` || location.pathname === `/profile` || location.pathname === `/history` || location.pathname === `/invoice`
                            ?
                            null
                            :
                            <Carousel />
                    }
                    <div className="p-5 pb-24">
                        <Outlet />
                    </div>
                    {

                        location.pathname === `/checkout` || location.pathname === `/profile` || location.pathname === `/history` || location.pathname === `/invoice`
                            ?
                            null
                            :
                            totalItems === 0 ?
                                null
                                :
                                <Basket />
                    }
                </div>
                {/**
                 * End of Drawer Content (behind the drawer)
                 */}
                {/**
                 * Drawer Side 
                 */}
                <div className="drawer-side h-screen">
                    <label htmlFor="my-drawer-2" className="drawer-overlay" />
                    <ul className="menu p-4 overflow-y-auto w-80 bg-base-100 text-base-content">
                        <h1 className='font-extrabold text-3xl text-primary mb-10 pl-6'>JTP Mart</h1>
                        <li className="menu-title">
                            <span>
                                Products
                            </span>
                        </li>
                        <li>
                            <Link to='/foods'>
                                <FontAwesomeIcon icon={faUtensils} size='lg' className='mr-5' />
                                Foods
                            </Link>
                        </li>
                        <li>
                            <Link to='/drinks'>
                                <FontAwesomeIcon icon={faWineGlass} size='lg' className='mr-5' />
                                Drinks
                            </Link>
                        </li>
                    </ul>

                </div>
                {/**
                 * End of Drawer Side 
                 */}
            </div>
        </>
    );
};
