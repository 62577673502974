export const locationText = (location: string | null) => {
    if (location === 'JTP1') {
        return 'Jatim Park 1'
    } else if (location === 'JTP2') {
        return 'Jatim Park 2'
    } else if (location === 'JTP3') {
        return 'Jatim Park 3'
    } else if (location === 'ECO') {
        return 'Eco Green Park'
    } else if (location === 'SENYUM') {
        return 'Senyum World Hotel'
    } else if (location === 'MGG') {
        return 'Milenial Glow Garden'
    } else if (location === 'PFP') {
        return 'Predator Fun Park'
    } else if (location === 'POHONINN') {
        return 'Pohon Inn Hotel'
    } else if (location === 'MA') {
        return 'Museum Angkut'
    } else if (location === 'BALOGA') {
        return 'Batu Love Garden'
    } else {
        return 'null'
    }
}
