import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { GoogleLogout } from "react-google-login";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../store/store";
import { logout } from "../../store/userSlice";
import logoWa from "../../../public/order-lewat-whatsapp.png";

export const Unauthorized = () => {
  const globalStateUser = useSelector((state: RootState) => {
    return state.userData;
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userShop = globalStateUser?.userShop;

  useEffect(() => {
    if (userShop === null) {
      navigate("/locationpick");
    }
  }, []);

  const handleLogout = () => {
    navigate("/");
    dispatch(logout());
  };
  return (
    <>
      {/* <div className="bg-base-200">
                <GoogleLogout
                    clientId={(process.env.REACT_APP_GOOGLE_OAUTH_CLIENTID as string)}
                    render={renderProps => (
                        <button onClick={renderProps.onClick} disabled={renderProps.disabled}><FontAwesomeIcon icon={faSignOutAlt} className='fa-rotate-180' size='2x' /></button>
                    )}
                    buttonText="Logout"
                    onLogoutSuccess={handleLogout}
                >
                </GoogleLogout>
            </div> */}
      <div className="hero min-h-screen bg-base-200">
        <div className="text-center hero-content">
          <div className="max-w-md">
            <h1 className="mb-5 text-5xl font-bold">Akun anda belum aktif!</h1>
            <p className="mb-5">
              Silahkan hubungi admin untuk Mengaktifkan akun!
            </p>
            <div>
              <a
                href="https://api.whatsapp.com/send?phone=628123305579"
                target="_blank"
                className="btn btn-primary"
                rel="noopener"
              >
                Hubungi Admin
              </a>
            </div>
            <br />
            <GoogleLogout
              clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENTID as string}
              render={(renderProps) => (
                <button
                  onClick={renderProps.onClick}
                  className="btn btn-error px-11"
                >
                  Logout
                </button>
              )}
              buttonText="Logout"
              onLogoutSuccess={handleLogout}
            ></GoogleLogout>
          </div>
        </div>
      </div>
    </>
  );
};
