import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { RootState } from '../../store/store';

export const NoShop = () => {
    const userShop = useSelector((state: RootState) => {
        return state.userData.userShop
    });

    return userShop ? <Outlet /> : <Outlet />
};
